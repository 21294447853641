import store from "@/store";
/* eslint-disable no-console */
import IBooking from "@/models/booking";
import IParticipant from "@/models/participant";
import IRoom from "@/models/room";
import Infrastructure from "@/infrastructure";
import { Booking } from "@/grpc/code-gen/booking_pb";
import Router from "../router";

const infra = new Infrastructure();

export default {
  namespaced: true,
  state: {
    TimeSelectFrom: "00:00",
    TimeSelectTill: "00:00",
    scrollTo: 0,
    timeSelectChangeMarker: 0,
    submitEdit: 0,
    timeIsBusy: false,
    selectedDate: new Date(),
    selectedDateTimeline: new Date(),
    selectedDateBO: new Date(),
    idEditBooking: "",
    idRepeatBooking: "",
    bookingNotChange: true,
    selectedRoom: {
      name: "",
      id: "",
      floor: 0,
      space: 0,
      img: "undefined",
      equipment: [],
      bookings: []
    } as unknown as IRoom,
    selectedBooking: {} as Booking.AsObject,
    selectedBookingExp: false,
    bookings: [] as Booking.AsObject[],
    bookingsFutureListAll: [] as Booking.AsObject[],
    bookingsExpiredListAll: [] as Booking.AsObject[],
    mapIsVisible: false,
    selectedSeat: {
      id: "",
      number: 0,
      coworking: "",
      isavailable: false,
      booking: []
    },
    member: {
      name: "",
      email: "",
      car: ""
    }
  },
  actions: {
    async loadBookingsList(s: any, date: string): Promise<void> {
      await infra.booking
        .GetBookingsList(date)
        .then((response) => {
          if (date === new Date().toJSON().split("T")[0]) {
            s.commit("addBookingsList", response.bookingList);
          } else {
            s.commit("pushBookingsList", response.bookingList);
          }
        })
        .catch((error) => {
          console.log(error);
          store.commit("setNetworkError");
        });
    },
    async loadBookingsByDate(s: any, date: string): Promise<void> {
      await infra.booking
        .GetBookingsList(date)
        .then((response) => {
          s.commit("addBookingsList", response.bookingList);
        })
        .catch((error) => {
          console.log(error);
          store.commit("setNetworkError");
        });
    },
    async loadBookingFutureListAll(
      s: any,
      payload: {
        limit?: number;
        offset?: number;
        search?: string;
        date?: string;
        city?: string;
        building?: string;
        room?: string;
        onlywithout?: boolean;
      }
    ): Promise<void> {
      await infra.booking
        .GetBookingsFutureListAll(payload)
        .then((response) => {
          if (payload.offset == 0) {
            s.commit("addBookingsFutureListAll", response.bookingList);
          } else {
            s.commit("pushBookingsFutureListAll", response.bookingList);
          }
        })
        .catch((error) => {
          console.log(error);
          store.commit("setNetworkError");
        });
    },
    async loadBookingExpiredListAll(
      s: any,
      payload: {
        limit?: number;
        offset?: number;
        search?: string;
        date?: string;
        city?: string;
        building?: string;
        room?: string;
        onlywithout?: boolean;
      }
    ): Promise<void> {
      await infra.booking
        .GetBookingsExpiredListAll(payload)
        .then((response) => {
          if (payload.offset == 0) {
            s.commit("addBookingsExpiredListAll", response.bookingList);
          } else {
            s.commit("pushBookingsExpiredListAll", response.bookingList);
          }
        })
        .catch((error) => {
          console.log(error);
          store.commit("setNetworkError");
        });
    },
    addBooking(state: any, payload: Booking.AsObject): void {
      infra.booking
        .AddBooking(payload)
        .then((response) => {
          console.log("add booking success  ", response);
        })
        .catch((error) => {
          console.log("add booking failed", error);
          store.commit("setNetworkError");
        });
    },
    updBooking(state: any, payload: Booking.AsObject): void {
      infra.booking
        .UpdBooking(payload)
        .then((response) => {
          console.log("upd booking success  ", response);
        })
        .catch((error) => {
          console.log("upd booking failed", error);
          store.commit("setNetworkError");
        });
    },
    cancelBooking(state: any, payload: Booking.AsObject): void {
      infra.booking
        .UpdCancelBooking(payload)
        .then((response) => {
          console.log("cancel booking success  ", response);
          state.commit("deleteBooking", payload.id);
        })
        .catch((error) => {
          console.log("cancel booking failed", error);
          store.commit("setNetworkError");
        });
    },
    async selIdRoom(s: any, id: string): Promise<void> {
      return await new Promise((resolve, reject) => {
        infra.room
          .SelIdRoom(id)
          .then((response) => {
            if (response) {
              s.commit("setRoom", response.roomList[0]);
              resolve();
            } else {
              reject();
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async selIdBooking(s: any, id: string): Promise<void> {
      return await new Promise((resolve, reject) => {
        infra.booking
          .SelIdBooking(id)
          .then((response) => {
            if (response) {
              s.commit("setSelectedBooking", response.bookingList[0]);
              resolve();
            } else {
              reject();
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async addLostCopies(): Promise<void> {
      return await new Promise((resolve, reject) => {
        infra.booking
          .AddLostCopies()
          .then((response) => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async delLostCopies(): Promise<void> {
      return await new Promise((resolve, reject) => {
        infra.booking
          .DelLostCopies()
          .then((response) => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  } as any,
  mutations: {
    pushBookingsList(state: any, arr) {
      state.bookings = [...state.bookings, ...arr];
    },
    addBookingsList(state: any, arr) {
      state.bookings = arr;
    },
    pushBookingsFutureListAll(state: any, arr) {
      state.bookingsFutureListAll = [...state.bookingsFutureListAll, ...arr];
    },
    addBookingsFutureListAll(state: any, arr) {
      state.bookingsFutureListAll = arr;
    },
    pushBookingsExpiredListAll(state: any, arr) {
      state.bookingsExpiredListAll = [...state.bookingsExpiredListAll, ...arr];
    },
    addBookingsExpiredListAll(state: any, arr) {
      state.bookingsExpiredListAll = arr;
    },
    addTimeSelectFrom(state: any, from: string) {
      state.TimeSelectFrom = from;
    },
    addTimeSelectTill(state: any, till: string) {
      state.TimeSelectTill = till;
    },
    addTimeSelectChangeMarker(state: any) {
      state.timeSelectChangeMarker += 1;
    },
    pushBooking: (state: any, booking) => {
      state.bookings.reduce((acc, item) => {
        if (item.id === booking.id) {
          return [...acc, booking];
        }
        return [...acc, item];
      }, []);
      if (state.idEditBooking == "") {
        state.bookings.push({ ...booking });
      }
    },
    setDate(state: any, date: object) {
      state.selectedDate = date;
    },
    setDateTimeline(state: any, date: object) {
      state.selectedDateTimeline = date;
    },
    setDateBO(state: any, date: object) {
      state.selectedDateBO = date;
    },
    setRoom(state: any, room: object) {
      state.selectedRoom = room;
    },
    setTimeIsBusy(state: any, value) {
      state.timeIsBusy = value;
    },
    setSelectedBooking(state: any, booking: object) {
      state.selectedBooking = booking;
    },
    setSelectedBookingExp(state: any, payload: Boolean) {
      state.selectedBookingExp = payload;
    },
    addIdEditBooking(state: any, id: string) {
      state.idEditBooking = id;
    },
    addIdRepeatBooking(state: any, id: string) {
      state.idRepeatBooking = id;
    },
    addBookingNotChange(state: any, payload: boolean) {
      state.bookingNotChange = payload;
    },
    cancelBooking(state: any, id: string) {
      if (state.bookings.find((b) => b.id === id)) {
        state.bookings.find((b) => b.id === id).canceled = true;
      }
    },
    deleteBooking(state: any, id: string) {
      state.bookings = state.bookings.filter((b) => b.id !== id);
    },
    submitEdit(state: any) {
      state.submitEdit++;
    },
    setScrollTo(state: any, payload) {
      state.scrollTo = payload;
      setTimeout(() => {
        state.scrollTo = 0;
      }, 5000);
    },
    setMapIsVisible(state: any, payload: boolean) {
      state.mapIsVisible = payload;
    },
    setSeat(state: any, seat: object) {
      state.selectedSeat = seat;
    },
    setMemberData(state: any, member: object) {
      state.member = member;
    }
  },
  getters: {
    from: (s: any) => s.TimeSelectFrom,
    till: (s: any) => s.TimeSelectTill,
    timeSelectChangeMarker: (s: any) => s.timeSelectChangeMarker,
    submitEdit: (s: any) => s.submitEdit,
    getBookings: (s: any) => s.bookings,
    getBookingsFutureListAll: (s: any) => s.bookingsFutureListAll,
    getBookingsExpiredListAll: (s: any) => s.bookingsExpiredListAll,
    selectedDate: (s: any) => s.selectedDate,
    selectedDateBO: (s: any) => s.selectedDateBO,
    selectedDateTimeline: (s: any) => s.selectedDateTimeline,
    selectedRoom: (s: any) => s.selectedRoom,
    selectedBooking: (s: any) => s.selectedBooking,
    selectedBookingExp: (s: any) => s.selectedBookingExp,
    idEditBooking: (s: any) => s.idEditBooking,
    idRepeatBooking: (s: any) => s.idRepeatBooking,
    timeIsBusy: (s: any) => s.timeIsBusy,
    bookingNotChange: (s: any) => s.bookingNotChange,
    getBookingById: (s: any) => (id: string) => {
      return s.bookings.find((booking) => booking.id === id) || { id: 0 };
    },
    getBookingBySeat: (s: any) => (seatId: string) => {
      return s.bookings.find(
        (booking) => booking.seat === seatId && booking.canceled === false
      );
    },
    getBookingsByDateAndRooms: (s: any) => (dateJson: string, room) => {
      const date = dateJson.split("T")[0];
      return s.bookings.filter((booking) => {
        return (
          booking.room === room?.id &&
          date === booking.from.split(" ")[0] &&
          booking.canceled === false
        );
      });
    },
    getScrollTo: (s: any) => s.scrollTo,
    getMapIsVisible: (s: any) => s.mapIsVisible,
    selectedSeat: (s: any) => s.selectedSeat,
    setMember: (s: any) => s.member
  }
};
